import { Theme } from '@mui/material';
import { action, computed, makeObservable, observable } from 'mobx';
import { generatePath } from 'react-router';
import { clientRoute } from '../clientRoute';
import { RootStoreProps } from '../types/rootStore';
import { SearchParam } from '../types/searchParam';
import { RadarView } from '../types/settings';
import { setSearchParam } from '../utils/searchParams';
import { ApiStore } from './ApiStore';
import { CoreRootStore } from './CoreRootStore';
import { DiagramStore } from './DiagramStore';
import { NotificationStore } from './NotificationStore';

export const rootStoreObservables = {
    coreRootStore: observable,
    api: observable,
    notificationStore: observable,
    diagramStore: observable,
    theme: observable,

    features: observable,
    view: observable,
    isFullscreen: observable,

    isDefaultView: computed,

    toggleIsFullscreen: action.bound,
    setObservables: action.bound,
    setView: action.bound,
    setViewSearchParam: action.bound,
    setAppTheme: action.bound,
};

export class RootStore {
    coreRootStore: CoreRootStore;
    api: ApiStore;
    notificationStore: NotificationStore;
    diagramStore: DiagramStore;

    theme: Theme = {} as Theme;

    features: RootStoreProps['features'] = {};
    view: RadarView;
    isFullscreen = false;

    constructor(props: RootStoreProps) {
        const { view = RadarView.default, theme } = props;
        this.setObservables(props);

        this.coreRootStore = new CoreRootStore(props);
        this.notificationStore = new NotificationStore();
        this.api = new ApiStore(this);
        this.diagramStore = new DiagramStore(this);

        this.view = view;
        this.setAppTheme(theme);

        makeObservable(this, rootStoreObservables);
    }

    get isDefaultView(): boolean {
        return this.view === RadarView.default;
    }

    toggleIsFullscreen(): void {
        this.isFullscreen = !this.isFullscreen;
    }

    setObservables(props: RootStoreProps): void {
        const { features } = props;
        this.features = features;
    }

    setView(id: string, view: RadarView | null): void {
        if (view) {
            this.view = view;
            return;
        }

        this.setViewSearchParam(id, RadarView.default);
    }

    setViewSearchParam(id: string, view: RadarView): void {
        const newSearchParams = setSearchParam(SearchParam.view, view);
        this.coreRootStore.history.push({
            pathname: generatePath(clientRoute.dataset, { id }),
            search: newSearchParams,
        });
    }

    setAppTheme(theme: Theme): void {
        this.theme = theme;
    }
}
